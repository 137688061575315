/* src/Components/Login.css */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #fff;
  }
  
  .login-container h1 {
    color: #b22222;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 150px;
  }
  
  /* Google Sign-In Button Container */
  .google-login-container {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  /* Separator */
  .or-separator {
    margin: 20px 0;
    font-weight: bold;
    color: #555;
  }
  
  /* Login Form */
  .login-container form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .login-container input {
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 2px solid #b22222;
    border-radius: 5px;
  }
  
  .login-container button {
    padding: 10px;
    background-color: #b22222;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .login-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .login-container p {
    margin-top: 15px;
    font-size: 14px;
  }
  
  .login-container a {
    color: #b22222;
    text-decoration: none;
  }
  
  .login-container a:hover {
    text-decoration: underline;
  }
  